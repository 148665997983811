import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from '@headlessui/react';
import { faChevronDown, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationManager } from "react-notifications";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { faAddressBook, faCity, faEnvelope, faEye, faEyeSlash, faInfoCircle, faInstitution, faLock, faMobile, faStreetView, faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Spin } from "react-cssfx-loading";
import Languages from '../../../constances/languages.json'
import { GetUserLogin, GetUsers } from "../../../services";
import BackgroudImg from '../../assets/GreSLERN-logo.png';
import TermsAndConditionsContents from "../../../pages/LandingPage/TermsAndConditions/contents";

interface SignUpCredentials {
  institutionName: string;
  password: string;
  name: string;
  email: string;
  phone: string;
  position: string;
  username: string;
  type: string;
  country: string;
  city: string;
  streetAddress: string;
  zipCode: string;
  attempt: number;
  gender: string;
}
interface InstitutionType {
  label: string;
  value: string;
}
interface GenderType {
  label: string;
  value: string;
}
export type InstituteData = {
  name: string;
  country: string;
  contactEmail: string;
  contactName: string;
  contactPosition: string;
  city: string;
  status: string;
  sendto: string;
}
export type InstituteDetailData = {
  phone: string;
  country: string;
  zipCode: string;
  type: string;
  city: string;
  streetAddress: string;
  gender: string;
  _id: string;
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const initialValue = {
  institutionName: "",
  password: "",
  name: "",
  email: "",
  phone: "",
  position: "",
  username: "",
  type: "",
  country: "",
  city: "",
  streetAddress: "",
  zipCode: "",
  gender: "",
  attempt: 0,
}

function InstitutionalForm({ handleGoRightClick }: { handleGoRightClick: () => void }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [signupCredentials, setSignupCredentials] = useState<SignUpCredentials>(initialValue);
  const [currentStep, setCurrentStep] = useState(1);
  const [isPassValid, setPassIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [regUserId, setRegUserId] = useState();

  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(true);
  const minLength = 8;
  const [type, setInstituteType] = useState<InstitutionType | null>(null);
  const [instituteData, setInstituteData] = useState<InstituteData | null>(null);
  const [instituteDetailData, setInstituteDetailData] = useState<InstituteDetailData | null>(null);
  const [showtermcondition, setShowtermcondition] = useState(false);
  const [showPrivacyandCompliance, setPrivacyandCompliance] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('languageName') || 'English');
  const language = Languages.slice(0, 4);
  const languagesSecond = Languages.slice(4);

  const handleButtonClick = (name: string, value: string) => {
    setSelectedLanguage(name);
    localStorage.setItem("language", value)
    localStorage.setItem("languageName", name)
    i18n.changeLanguage(value);
  };
  const typeOptions: InstitutionType[] = [
    { label: "School", value: "School" },
    { label: "College", value: "College" },
    { label: 'University', value: 'University' },
    { label: "Training center", value: "Training center" },
    { label: 'Other', value: 'Other' },
  ];
  const genderOptions: GenderType[] = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "I prefer not to say", value: "I prefer not to say" }
  ];
  function toggleShowPassword() {
    setIsPasswordVisible((prevState) => !prevState);

  }
  const openModal = () => {
    setShowtermcondition(!showtermcondition);
    setPrivacyandCompliance(!showPrivacyandCompliance);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupCredentials({
      ...signupCredentials,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "password") {
      setPassIsValid(e.target.value.length >= minLength);
    }
  };

  const handleToggleCheckbox = () => {
    // Toggle the checkbox state
    setIsChecked(true);
    setShowtermcondition(!showtermcondition);
  };

  const handleToggleCheckboxPrivacy = () => {
    // Toggle the checkbox state
    setIsCheckedPrivacy(true);
    setPrivacyandCompliance(!showPrivacyandCompliance);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { password, phone, type, city, streetAddress, zipCode, email } = signupCredentials;
      if (!password) {
        NotificationManager.warning("All filed Required", "SignUp");
        setLoading(false);
        return;
      }
      if (instituteData?.status === '1') {
        if (!password || !phone || !type) {
          NotificationManager.warning("All filed Required", "SignUp");
          setLoading(false);
          return;
        }
        await GetUserLogin.signupInstitute({
          institutionName: instituteData?.name,
          password,
          email: instituteData?.contactEmail,
          phone,
          type,
          country: instituteData?.country,
          city,
          streetAddress,
          zipCode,
          user: regUserId,
          flag: '2',
        }).then((response) => {
          if (response) {
            NotificationManager.success("User Register Successfull", "SignUp");
            setSignupCredentials(initialValue);
            setLoading(false);
            navigate("/login");
          } else {
            setLoading(false);
          }
        });
      }
      else if (instituteData?.status === '2') {
        await handleuserSignup().then(async (userId) => {
          if (userId) {
            await handleinstituteSignup(userId);
          }
        }).catch((error) => {
          console.log(error)
        })
      }
      else {
        await handleuserSignup().then(async (userId) => {
          if (userId) {
            await handlenewinstituteSignup(userId);
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    } catch (error) {
      // Handle errors that might occur during signup
      console.error("Error during signup:", error);
      NotificationManager.error("Error during signup, Try Again", "SignUp");
      setLoading(false);
      // Optionally display an error message to the user or take other actions
      // ...
    }
  };
  const handleuserSignup = async () => {
    const { password, gender } = signupCredentials;
    if (!password || !gender) {
      NotificationManager.warning("All filed Required", "SignUp");
      setLoading(false);
      return;
    }
    const response = await GetUserLogin.signup({
      password,
      name: instituteData?.contactName,
      email: instituteData?.contactEmail,
      type: "institute",
      gender,
      country: instituteData?.country,
      attempt: 0,
    });
    if (response) {
      const userId1 = response._id;
      return userId1;
    } else {
      // Handle errors in user 1 registration
    }
  }
  const handlenewinstituteSignup = async (userId) => {
    const { phone, type, city, streetAddress, zipCode } = signupCredentials;
    if (!phone || !type || !city || !streetAddress || !zipCode) {
      NotificationManager.warning("All filed Required", "SignUp");
      setLoading(false);
      return;
    }
    await GetUserLogin.signupInstitute({
      institutionName: instituteData?.name,
      email: instituteData?.contactEmail,
      phone,
      type,
      country: instituteData?.country,
      city,
      streetAddress,
      zipCode,
      user: userId,
      flag: '1',
    }).then((response) => {
      if (response) {
        NotificationManager.success("User Register Successfull", "SignUp");
        setSignupCredentials(initialValue);
        setLoading(false);
        navigate("/login");
      } else {
        setLoading(false);
      }
    });
  }
  const handleinstituteSignup = async (userId) => {

    await GetUserLogin.signupInstitute({
      institutionName: instituteData?.name,
      flag: '3',
      email: instituteData?.contactEmail,
      phone: instituteDetailData?.phone,
      type: instituteDetailData?.type,
      country: instituteData?.country,
      city: instituteDetailData?._id,
      streetAddress: instituteDetailData?.streetAddress,
      zipCode: instituteDetailData?.zipCode,
      user: userId,
    }).then((response) => {
      if (response) {
        NotificationManager.success("User Register Successfull", "SignUp");
        // Clear form fields and reset selections after successful signup
        setSignupCredentials(initialValue);
        setLoading(false);
        navigate("/login");
      } else {
        setLoading(false);
      }
    });
  }
  const fetchSignUpInstitute = async () => {
    const data = await GetUsers.getInvitedUserbyToken(token ? token : "");
    if (data) {
      setInstituteData(data[0]);
      checkUser(data[0]?.sendto);
      if (data[0]?.status === '1') {
        await fetchUserDetail(data[0]?.email);
      }
      if (data[0]?.status === '2') {
        await fetchInstituteDetail(data[0]?.name);
      }
    }
    else if (data.length <= 0) {
      setInstituteData(null);
      navigate("/home");
    }
    else {
      setInstituteData(null);
    }
  }
  const fetchInstituteDetail = async (name: string) => {
    const data = await GetUsers.getInstitutebyNamePublic(name);
    if (data) {
      setInstituteDetailData(data);
    }
    else {
      setInstituteData(null);
    }
  }
  const fetchUserDetail = async (email: string) => {
    const data = await GetUsers.getUserbyemailPublic(email);
    if (data) {
      setRegUserId(data[0]._id);
    }
  }
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };
  const checkUser = (sendtoUser) => {
    if (sendtoUser !== 'institute') {
      if (sendtoUser === undefined) {
        navigate("/home");
        return;
      }
      navigate(`/signup/${token}`);
      return;
    }
  }
  useEffect(() => {
    fetchSignUpInstitute();
  }, [])
  return (
    <section className="py-20 bg-gray-100  bg-opacity-50 h-screen flex items-center">
      <div className="mx-auto container max-w-4xl md:w-3/4 shadow-md">
        <div className="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-yellow-400 rounded-t">
          <div className="max-w-sm mx-auto md:w-full md:mx-0">
            <div className="inline-flex items-center space-x-4">
              <img
                className="w-210 h-210 object-cover "
                alt="User avatar"
                src={BackgroudImg}
                onClick={() => navigate('/')}
              />

              <h1 className="text-orange" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                {t("Registration Form")}
              </h1>
            </div>
          </div>
        </div>
        <form onSubmit={handleSignUp}>
          <div className="bg-white space-y-6 rounded">
            {/* Step 1: Institution Information */}
            {currentStep === 1 && (
              <>
                <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-orange items-center">
                  <h2 className="md:w-1/3 max-w-sm mx-auto">{t("Institution Information")}</h2>
                  <div className="md:w-2/3 max-w-sm mx-auto">
                    <label className="text-sm text-orange">{t("Institution Name")}</label>
                    <div className="w-full inline-flex border">
                      <div className="flex items-center justify-center p-2 w-1/12 bg-gray-100 bg-opacity-50">
                        <FontAwesomeIcon icon={faInstitution} className="me-1 text-orange" style={{ fontSize: '1.3rem' }} />
                      </div>
                      <input
                        disabled
                        className="w-11/12 focus:outline-none focus:text-gray-600 p-2"
                        id="institutionName"
                        name="institutionName"
                        type="text"
                        placeholder={t("Enter Institute/School")}
                        value={instituteData?.name}
                        required />
                    </div>
                    <label className="text-sm text-orange">{t("Institution Type")}</label>
                    <div className="w-full inline-flex border">
                      <select
                        disabled={instituteData?.status === '2' ? true : false}
                        id="type"
                        name="type"
                        value={instituteData?.status === '2' ? instituteDetailData?.type : signupCredentials.type}
                        // value={type ? type.value : ""}
                        onChange={(e) => {
                          const selectedOptionValue = e.target.value;
                          let selectedOption: InstitutionType | null = null;
                          const foundOption = typeOptions.find(
                            (option) => option.value === selectedOptionValue
                          );
                          if (foundOption) {
                            selectedOption = foundOption;
                          }
                          setInstituteType(selectedOption);
                          setSignupCredentials({
                            ...signupCredentials,
                            type: selectedOptionValue
                          });
                        }}
                        className="w-11/12 focus:outline-none focus:text-orange p-2"
                      >
                        <option className="text-[#292929]" hidden>
                          {t("Institution type")}
                        </option>
                        {typeOptions.map((option, k) => (
                          <option
                            key={`$typeOptios${k}${option.value}`}
                            className="text-[#292929]"
                            value={option.value}
                          >
                            {t(option.label)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="text-sm text-orange">{t("Country")}</label>
                      <div className="w-full inline-flex border">
                        <input
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="country"
                          name="country"
                          type="text"
                          placeholder={t("Country")}
                          value={instituteData?.country}
                          onChange={(e) =>
                            setSignupCredentials({
                              ...signupCredentials,
                              country: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                  <div className="flex justify-end w-full mx-2">
                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white" onClick={handleNext}>
                      {t("Next")}
                    </button>
                  </div>
                </div>
              </>
            )}
            {/* Step 2: Address Details */}
            {currentStep === 2 && (
              <>
                <div className="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-orange items-center">
                  <h2 className="md:w-1/3 mx-auto max-w-sm">{t("Address Details of your institution")}</h2>
                  <div className="md:w-2/3 mx-auto max-w-sm space-y-5">
                    <div>
                      <label className="text-sm text-orange">{t("City")}</label>
                      <div className="w-full inline-flex border">
                        <input
                          disabled={instituteData?.status === '2' ? true : false}
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="city"
                          name="city"
                          type="text"
                          placeholder={t("City")}
                          value={instituteData?.status === '2' ? instituteDetailData?.city : signupCredentials.city}
                          // value={instituteData?.city}
                          onChange={(e) =>
                            setSignupCredentials({
                              ...signupCredentials,
                              city: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm text-orange">{t("Street Address")}</label>
                      <div className="w-full inline-flex border">
                        <div className="py-2 px-1 w-1/12 bg-gray-100">
                          <FontAwesomeIcon icon={faStreetView} className="me-1 text-orange" style={{ fontSize: '1.3rem' }} />
                        </div>
                        <input
                          disabled={instituteData?.status === '2' ? true : false}
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="streetAddress"
                          name="streetAddress"
                          type="text"
                          placeholder={t("Street ")}
                          value={instituteData?.status === '2' ? instituteDetailData?.streetAddress : signupCredentials.streetAddress}
                          // value={signupCredentials.streetAddress}
                          onChange={(e) =>
                            setSignupCredentials({
                              ...signupCredentials,
                              streetAddress: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm text-orange">{t("Zip/Postal Code")} </label>
                      <div className="w-full inline-flex border">
                        <div className="py-2 px-1 w-1/12 bg-gray-100">
                          <FontAwesomeIcon icon={faAddressBook} className="me-1 text-orange" style={{ fontSize: '1.3rem' }} />
                        </div>
                        <input
                          disabled={instituteData?.status === '2' ? true : false}
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="zipCode"
                          name="zipCode"
                          type="text"
                          placeholder={t("Zip /Postal Code")}
                          value={instituteData?.status === '2' ? instituteDetailData?.zipCode : signupCredentials.zipCode}
                          // value={signupCredentials.zipCode}
                          onChange={(e) =>
                            setSignupCredentials({
                              ...signupCredentials,
                              zipCode: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                  <div className="flex justify-between w-full mx-2">
                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white" onClick={handlePrevious}>
                      {t("Previous")}
                    </button>
                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white" onClick={handleNext}>
                      {t("Next")}
                    </button>
                  </div>
                </div>
              </>
            )}
            {/* Step 3: Contact Information */}
            {currentStep === 3 && (
              <>
                <div className="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-orange items-center">
                  <h2 className="md:w-1/3 mx-auto max-w-sm">{t("Contact Information")}</h2>
                  <div className="md:w-2/3 mx-auto max-w-sm space-y-5">
                    <div>
                      <label className="text-sm text-orange">{t("Full Name")}</label>
                      <div className="w-full inline-flex border">
                        <div className="w-1/12 py-2 px-1 bg-gray-100">
                          <FontAwesomeIcon icon={faUser} className="me-1 text-orange" style={{ fontSize: '1.3rem' }} />
                        </div>
                        <input
                          disabled
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="name"
                          name="name"
                          type="text"
                          placeholder={t("EnterName")}
                          value={instituteData?.contactName}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm text-orange">{t("Phone")}</label>
                      <div className="w-full inline-flex border">
                        <div className="py-2 px-1 w-1/12 bg-gray-100">
                          <FontAwesomeIcon icon={faMobile} className="me-1 " style={{ fontSize: '1.3rem' }} />
                        </div>
                        <input
                          disabled={instituteData?.status === '2' ? true : false}
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="phone"
                          name="phone"
                          type="text"
                          placeholder={t("Enter Phone")}
                          value={instituteData?.status === '2' ? instituteDetailData?.phone : signupCredentials.phone}
                          onChange={(e) =>
                            setSignupCredentials({
                              ...signupCredentials,
                              phone: e.target.value,
                            })
                          }
                          required />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm text-orange">{t("Sex")} </label>
                      <div className="w-full inline-flex border">
                        <div className="py-2 px-1 w-1/12 bg-gray-100">
                          <FontAwesomeIcon icon={faUserCheck} className="me-1 text-orange" style={{ fontSize: '1.3rem' }} />
                        </div>
                        <select
                          id="gender"
                          name="gender"
                          // disabled={instituteData?.status === '2' ? true : false}
                          value={instituteData?.status === '2' ? instituteDetailData?.gender : signupCredentials.gender}
                          onChange={(e) => {
                            const selectedOptionValue = e.target.value;
                            let selectedOption: InstitutionType | null = null;
                            const foundOption = typeOptions.find(
                              (option) => option.value === selectedOptionValue
                            );
                            if (foundOption) {
                              selectedOption = foundOption;
                            }
                            setInstituteType(selectedOption);
                            setSignupCredentials({
                              ...signupCredentials,
                              gender: selectedOptionValue
                            });
                          }}
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                        >
                          <option className="text-[#292929]" hidden>
                            {t("SelectGender")}
                          </option>

                          {genderOptions.map((option, k) => (
                            <option
                              key={`$gender${k}${option.value}`}
                              className="text-[#292929]"
                              value={option.value}
                            >
                              {t(option.label)}
                            </option>
                          ))}
                        </select>
                        {/* <input
                          disabled
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="position"
                          name="position"
                          type="text"
                          placeholder={t("Position/Title")}
                          value={instituteData?.contactPosition}
                          required /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                  <div className="flex justify-between w-full mx-2">
                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white" onClick={handlePrevious}>
                      {t("Previous")}
                    </button>
                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 py-1 hover:bg-greslernorange hover:text-white" onClick={handleNext}>
                      {t("Next")}
                    </button>
                  </div>
                </div>
              </>
            )}
            {/* Step 4: Account Setup */}
            {currentStep === 4 && (
              <>
                <div className="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-orange items-center">
                  <h2 className="md:w-1/3 mx-auto max-w-sm">{t("Account Setup")}</h2>
                  <div className="md:w-2/3 mx-auto max-w-sm space-y-5">
                    <div className="hidden">
                      <label className="text-sm text-orange">{t("Email")}</label>
                      <div className="w-full inline-flex border">
                        <div className="py-2 px-1 w-1/12 bg-gray-100">
                          <FontAwesomeIcon icon={faEnvelope} className="me-1 text-orange" style={{ fontSize: '1.3rem' }} />
                        </div>
                        <input
                          disabled
                          className="w-11/12 focus:outline-none focus:text-orange p-2"
                          id="email"
                          name="email"
                          type="email"
                          placeholder={t("EnterEmail")}
                          value={instituteData?.contactEmail}
                          required />
                      </div>
                    </div>
                    <div className="relative w-full inline-flex border" style={{ position: 'relative', width: '100%' }}>
                      <div className="py-2 px-1 w-1/12 bg-gray-100">
                        <FontAwesomeIcon icon={faLock} className="me-1 text-orange" style={{ fontSize: '1.3rem' }} />
                      </div>
                      <input
                        // readOnly
                        className={`  w-11/12 focus:outline-none focus:text-orange p-2
                      ${isPassValid ? "" : "border-red-500"}`}
                        id="password"
                        name="password"
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder={t("EnterPassword")}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                      {!isPassValid && (
                        <div className="text-red-500 text-sm">
                          {t("passwordlength")} {minLength} {t("characterlong")}
                        </div>
                      )}
                      <button
                        className="absolute top-[50%] right-0 transform translate-y-[-50%] flex justify-center items-center w-[50px] h-[27px] rounded-[10px] border-none outline-none bg-transparent text-greslernorange"
                        type="button"
                        onClick={toggleShowPassword}
                      >
                        {isPasswordVisible ? (
                          <FontAwesomeIcon className="text-2xl" icon={faEye} />
                        ) : (
                          <FontAwesomeIcon className="text-2xl" icon={faEyeSlash} />
                        )}
                      </button>
                    </div>
                    <div className="flex flex-col justify-center space-y-2">
                      <div className="flex items-center">
                        <input
                          required
                          id="link-checkbox"
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => setIsChecked(!isChecked)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="link-checkbox"
                          className="ml-2 text-sm font-medium text-greslerorange dark:text-gray-300">
                          {t("Iagrewith")}{" "}
                          <button
                            onClick={() => setShowtermcondition(true)}
                            // onClick={() => openModal}
                            className="text-g-blue dark:text-blue-500 hover:underline">
                            {t("termsAndConditions")}
                          </button>
                        </label>
                      </div>

                      {/* <div className="flex items-center ">
                        <input
                          required
                          id="linkprivacy-checkbox"
                          type="checkbox"
                          checked={isCheckedPrivacy}
                          onChange={() => setIsCheckedPrivacy(!isCheckedPrivacy)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="linkprivacy-checkbox"
                          className="ml-2 text-sm font-medium text-greslernorange dark:text-gray-300">
                          {t("Iagrewith")}{" "}
                          <button
                            onClick={() => setPrivacyandCompliance(true)}
                            // onClick={() => openModal}
                            className="text-g-blue dark:text-blue-500 hover:underline">
                            {t("dataPrivacyPolicy")}
                          </button>
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="md:inline-flex w-full space-y-4 md:space-y-0 px-8 py-2 text-orange items-center">
                  <div className="flex justify-between w-full mx-2">
                    <button type="button" className="border-2 border-greslernorange border-solid rounded px-4 hover:bg-greslernorange hover:text-white" onClick={handlePrevious}>
                      {t("Previous")}
                    </button>
                    <button
                      className={`text-white rounded-md text-center border-2 border-greslernorange border-solid py-2 px-4 inline-flex items-center focus:outline-none md:float-right  
                        ${loading
                          ? "bg-[#2d2d39] text-white pointer-events-none"
                          : "bg-orange  hover:!text-greslernorange hover:bg-white"
                        }
                        `}
                      type="submit"
                      disabled={loading}>
                      {loading ? (
                        <Spin color="#ffffff" width="30px" height="30px" duration="1s" />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faUserPlus} className="me-1 " style={{ fontSize: '1.3rem' }} />
                          {t("Submit")}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
            <hr />
            <div className='row mt-2 mx-auto max-w-[50%]'>
              {language.map((item) => (
                <div
                  key={item.id}
                  className={selectedLanguage === item.name ? 'lng-btn selected col' : 'lng-btn col'}
                  onClick={() => handleButtonClick(item.name, item.value)}
                  style={{
                    color: selectedLanguage === item.name ? '#b55e2e' : '#243946', // Set the text color conditionally
                  }}
                >
                  {item.name}
                </div>
              ))}
              <Menu as="div" className="relative inline-block text-left h-full w-auto">
                <Menu.Button className="w-auto h-full text-slate-600  flex justify-center items-center border-0  p-2 hover:!border-[#007BFF]">
                  <FontAwesomeIcon icon={faChevronDown} className=" h-5 w-5 text-greslernorange" aria-hidden="true" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute bottom-0 left-16 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {languagesSecond?.map((item) => (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active ? 'bg-[#FF6347] text-white' : 'text-gray-700',
                                'block w-full px-4 py-2 text-left text-sm'
                              )}
                              key={item.id}
                              onClick={() => handleButtonClick(item.name, item.value)}
                            >
                              {item.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </form>
        {showtermcondition ? (
          <Modal
            show={true}
            onHide={openModal}
            className="w-full h-full bg-transparent opacity-80 mt-12 ">
            <Modal.Body className="max-h-full overflow-y-scroll ">
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-greslernorange">
                <h3 className="text-xl font-semibold text-greslernorange">
                  {t("termsofService")}
                </h3>
              </div>
              <div className="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
                <TermsAndConditionsContents />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex items-center p-6 space-x-2 border-t border-greslernorange rounded-b dark:border-greslernorange">
                <button
                  onClick={handleToggleCheckbox}
                  data-modal-hide="defaultModal"
                  type="button"
                  className="text-white bg-greslernorange/80 hover:bg-greslernorange focus:ring-4 focus:outline-none focus:ring-greslernorange font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-greslernorange/80 dark:hover:bg-greslernorange/80 dark:focus:ring-greslernorange/80">
                  Accept
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        ) : undefined}
        {/* {showPrivacyandCompliance ? (
          <Modal
            show={true}
            onHide={openModal}
            className="w-full h-full bg-transparent opacity-80 mt-12">
            <Modal.Body>
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 text-black">
                  Data Privacy Policy
                </h3>
              </div>
              <div className="p-6 space-y-6">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  The organisation of southern cooperation(OSC) is committed to protecting the privacy of our users and comply with GDPR data privacy guidelines. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our application that integrates other modules and external Services.
                </p>
                <div>
                  <h3>Information We Collect</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    We may collect the following types of information:
                    <br /> <br />
                    Personal Information: Specific personal information collected by GreSLERN includes name, email address, Gender, and country of Origin.
                    <br /> <br />
                    Usage Information: We collect information about how you interact with our application.
                  </p>
                </div>
                <div>
                  <h3>How We Use Your Information</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    We use the collected information for:
                    <br /> <br />
                    Providing and improving our services.
                    <br /> <br />
                    Personalizing your experience.
                    <br /> <br />
                    Analyzing usage patterns for optimizing our application.
                    <br /> <br />
                    Complying with legal obligations.
                  </p>
                </div>
                <div>
                  <h3>Data Sharing and Third-Party Integration</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    GreSLERN may send anonymized statistical information with third-party service providers for the purpose of analytics. Our application integrates the H5P module, and by using GreSLERN. it may send anonymized statistical information to H5P. This data helps H5P to learn about problems with their module that will in turn help us to improve GreSLERN. It does not contain personal data.
                  </p>
                </div>
                <div>
                  <h3>Cookies</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    GresLERN has content types that may use external services, and those may process personal data as demanded by Art. 6 GDPR users are informed that accessing those content.
                    <br /> <br />
                    In some content types, GreSLERN embed videos from YouTube. YouTube will set cookies that can be used to identify/track your users.
                    <br /> <br />
                    In some content types, GreSLERN embed feeds from Twitter. Twitter will set cookies that can be used to identify/track your users.
                  </p>
                </div>
                <div>
                  <h3>Voice</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    In some content types, GreSLERN offer speech recognition. This service is currently possible using Chrome, which will use the Google Cloud services to convert voice to text.
                  </p>
                </div>
                <div>
                  <h3>Results</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    GreSLERN will store data about users’ interactions on the platform. The results are to give the Student/Learner of a content the level of understanding of the content through the various assessments methodologies in the platform.
                    <br />
                    <br />
                    The above information doesnot entails use of personal information and the content creator doesnot have information about learners who completed the assessment In compliance with Art. 6 GDPR , Users are notified that the information about results is only known to the user.
                    <br />
                    <br />
                    It may be in your interest to process these data to learn something about your users, which may in turn help you to improve their learning outcome.
                    <br />
                    <br />
                    For each interaction that a user completes, GreSLERN may store the user’s account identifier, the start time, the finish time, the score that was achieved and the maximum score possible.
                  </p>
                </div>
                <div>
                  <h3>Saved content state</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    GresLERN offers to save the content state of an interaction. GreSLERN will store the state of content type that a user is interacting with in regular intervals and when it is completed. As demanded by Art. 6 GDPR Users are notified that the above action does not Miss Use their personal information except for analytical purposes and progress tracking for the User/ Learner.
                    <br /> <br />
                    It may be in your interest to save the content state. It may be used to provide your users with the opportunity to interrupt their interactions and continue where they took off at a later point in time. It may also be used to provide your users with the option to review their interactions (e.g., their answers given) at a later point in time.
                    <br /> <br />
                    For each content/sub-content that a user interacts with, GreSLERN will store the current state (e.g., answers given, if a tutorial has already been watched, etc.), when the state was saved, and some technical information (should it be preloaded, should the data be reset when the content is changed by the author).
                  </p>
                </div>
                <div>
                  <h3>Security Measures </h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    We implement security such as encryption of data, and other cybersecurity industry standards for application security and measures to protect your information from unauthorized access, disclosure, alteration, and destruction.
                  </p>
                </div>
                <div>
                  <h3>Your Choices </h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    You can choose not to provide certain information, but this may limit your ability to use certain features of our application.
                    <br /> <br />
                    Users have the right to Access, rectify, erase, and export data when using the GreSLERN Application., As demanded in the  (Art. 15 GDPR), export (Art. 20 GDPR), rectify (Art. 16 GDPR) or erase (Art. 17 GDPR) personal data through their profile settings.                     </p>
                </div>
                <div>
                  <h3>Updates to This Privacy Policy </h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    We may update this Privacy Policy to reflect changes in our practices. We will notify you of any material changes.
                    <br /> <br />
                    Contact Us: If you have any questions or concerns about this Privacy Policy, please contact us at tide@osc-ocs.org
                  </p>
                </div>
                <div>
                  <h3>References</h3>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    H5P Privacy Policy Guide. Available at: [H5P Privacy Policy Guide]
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  onClick={handleToggleCheckboxPrivacy}
                  data-modal-hide="defaultModal"
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  {t("Accept")}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        ) : undefined} */}
      </div>
    </section>
  );
}

export default InstitutionalForm;

function async(arg0: string) {
  throw new Error("Function not implemented.");
}