import { useEffect, useState } from "react";
import { FrequentService, GetUsers } from "../../services";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { selectInstitute } from "../../redux/Reducer/authSlice";
import countries from 'countries-list';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIcons, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import ContentHeader from "../root/dashboard/addon/contentHeader";
interface InstitutionType {
  label: string;
  value: string;
}
interface InstitutionType {
  label: string;
  value: string;
}
const InstituteProfile = ({ users = [] }) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState("");
  const [taglines, setTaglines] = useState("");
  const [logo, setLogo] = useState<File | null>(null);
  const [cover, setCover] = useState<File | null>(null);
  const [country, setSelectedCountry] = useState('');
  const countryList = Object.values(countries.countries);
  const [contactName, setContactName] = useState('');
  const [phone, setPhone] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const instituteId = useSelector(selectInstitute)
  const [type, setInstituteType] = useState<InstitutionType | null>(null);
  const typeOptions: InstitutionType[] = [
    { label: "School", value: "School" },
    { label: "College", value: "College" },
    { label: 'University', value: 'University' },
    { label: "Training center", value: "Training center" },
    { label: "University", value: "University" },
    { label: 'Other', value: 'Other' },
  ];
  const { t } = useTranslation();

  const basicInstituteUpdate = async (e) => {
    e.preventDefault();

    const updatedata = {
      instituteName: name,
      country: country,
      city: city,
      streetAddress: streetAddress,
      zipCode: zipCode,
      type: type,
    }
    try {
      let data = await GetUsers.institutedataupdate(instituteId, updatedata)
      if (data) {
        NotificationManager.success("Successfully updated", "User profile");
      }
      else {
        NotificationManager.warning("Profile not updated", "User profile")
      }
    } catch (error) {
      console.error('Error during update:', error);
    }
  }
  const updateBranding = async (e) => {
    e.preventDefault();
    if (logo || cover) {
      const userdata = new FormData();
      userdata.append("logo", logo);
      userdata.append("cover", cover);
      userdata.append("taglines", taglines);
      userdata.append("description", description);
      try {
        const data = await GetUsers.updateinstitutebrand(instituteId, userdata)
        if (data.status === 200) {
          NotificationManager.success(`${data.data}`, "Institute data");
        }
        else {
          NotificationManager.warning(`${data.data}`, "Institute data")
        }
      } catch (error) {
        console.error('Error during change:', error);
      }
    }

  }
  const validateImage = (e, file) => {
    const maxSize = 3 * 1024 * 1024; // 3MB
    const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];
    if (file.size > maxSize) {
      alert('Image size is too large. Maximum size allowed is 5MB.');
      return;
    }
    if (!allowedFormats.includes(file.type)) {
      alert('Invalid image format. Only JPEG, PNG, and GIF formats are allowed.');
    }
  };
  const handleLogo = (e: any) => {
    e.preventDefault();
    const image1 = e.target.files[0];
    validateImage(e, image1);
    setLogo(image1);
  }
  const handleCover = (e) => {
    e.preventDefault();
    const image2 = e.target.files[0];
    validateImage(e, image2);
    setCover(image2);
  }
  const handleCountry = (e) => {
    e.preventDefault();
    setSelectedCountry(e.target.value);
  }
  const handleType = (e) => {
    e.preventDefault();
    setInstituteType(e.target.value);
  }
  const getuserdata = async () => {
    let data = await GetUsers.getinstitutesingledetail(instituteId);
    if (data) {
      setName(data.institutionName);
      setContactName(data.name);
      setSelectedCountry(data.country);
      setDescription(data.description);
      setCity(data.city);
      setStreetAddress(data.streetAddress);
      setZipCode(data.zipCode);
      setPhone(data.phone);
      setEmail(data.email);
      setInstituteType(data.type);
      setTaglines(data.taglines);
      setLogo(data.logo);
      setCover(data.cover);
    }
  }
  const addtousedlink = async () => {
    const useddata = {
      usedlink: "profile"
    }
    try {
      await FrequentService.addusedlink(useddata);
    } catch (error) { }
  };
  useEffect(() => {
    if (instituteId) {
      addtousedlink();
      getuserdata();
    }
  }, [instituteId]);
  return (
    <div className="w-full h-auto">
      <div className="md:py-[1px] md:px-[2px]">
        <div className="w-full h-auto">
          <div className="md:py-[16px] md:px-[32px]">
            <ContentHeader title={t("institution profile")} showIcon="false" />
            <div className=" rounded-[0.375rem] sm:grid-cols-6">
              <div className=" rounded-[0.375rem]  bg-shape-pattern bg-cover !bg-opacity-0 p-3 ">
                <div className='sm:flex-wrap'>
                </div>
                <div className=''>
                  <form>
                    <div className="flex  flex-wrap md:flex-row w-full">
                      <div className="bg-white rounded-lg min-w-fit sm:w-1/2 mb-2 p-3 !border-b-4 !border-b-greslernorange ">
                        <div className="text-[18px] font-bold uppercase text-greslernorange ">
                          <FontAwesomeIcon icon={faInfoCircle} />
                          <span className="pl-2">
                            {t("Basic Information")}
                          </span>
                        </div>
                        <div className=" flex gap-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("companyname")}{" "}
                          </h2>
                          <input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                            placeholder={t("EnterName")}
                            required
                          />
                        </div>
                        <div className=" flex gap-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("institutetype")}{" "}
                          </h2>
                          <select
                            id="type"
                            name="type"
                            value={type || ""}
                            onChange={handleType}
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem] bg-white"
                            required>
                            <option className="text-[#292929]" hidden>
                              {t("userType")}
                            </option>
                            {typeOptions.map((option, k) => (
                              <option
                                key={`$typeOptios${k}${option.value}`}
                                className="text-[#292929]"
                                value={option.value}
                              >
                                {t(option.label)}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" flex gap-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("Country")}{" "}
                          </h2>
                          <select
                            required
                            id="country"
                            name="country"
                            value={country}
                            onChange={handleCountry}
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem] bg-white">
                            {countryList.map((country) => (
                              <option
                                className="text-[#292929]"
                                key={country.name}
                                value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" flex gap-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("city")}{" "}
                          </h2>
                          <input
                            id="city"
                            name="city"
                            type="text"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                            placeholder={t("entercity")}
                            required
                          />
                        </div>
                        <div className=" flex gap-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("streetaddress")}{" "}
                          </h2>
                          <input
                            id="streetaddress"
                            name="streetaddress"
                            type="text"
                            value={streetAddress}
                            onChange={(e) => setStreetAddress(e.target.value)}
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                            placeholder={t("enterstreetaddress")}
                            required
                          />
                        </div>
                        <div className=" flex gap-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("zipcode")}{" "}
                          </h2>
                          <input
                            id="zipcode"
                            name="zipcode"
                            type="text"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                            placeholder={t("enterzipcode")}
                            required
                          />
                        </div>
                        <div className="flex gap-2 py-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2"> </h2>
                          <div className="w-3/4 flex justify-start">
                            <button
                              onClick={basicInstituteUpdate}
                              className="border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-greslernorange">
                              {t("Save Change")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white rounded-lg min-w-fit sm:w-1/2 mb-2 p-3 !border-b-4 !border-b-greslernorange ">

                        {/* <div className="bg-white rounded-lg sm:w-full mb-2 p-3 !border-b-4 !border-b-greslernorange "> */}
                        <div className="text-[18px] font-bold text-greslernorange uppercase">
                          <FontAwesomeIcon icon={faIcons} />
                          <span className="pl-2">
                            {t("Branding")}
                          </span>
                        </div>
                        <div className="flex gap-2 py-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("Logo")}{" "}
                          </h2>
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            name="logo"
                            onChange={handleLogo}
                            className={`border-solid border-2 py-2 px-3 rounded-[0.375rem] file-input ${logo ? 'w-2/4' : 'w-full'}`}
                          />
                          {logo &&
                            <img
                              className="object-cover object-center w-30 h-20 rounded-lg pt-3"
                              alt="Logo Pic"
                              src={`/api/institute/logo/${email}/${logo}`}
                            />
                          }
                        </div>
                        <div className="flex gap-2 py-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("Cover")}{" "}
                          </h2>
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            name="cover"
                            onChange={handleCover}
                            className={`border-solid border-2 py-2 px-3 rounded-[0.375rem] file-input ${cover ? 'w-2/4' : 'w-full'}`}
                          />
                          {cover &&
                            <img
                              className="object-cover object-center w-30 h-20 rounded-lg pt-3"
                              alt="Cover Pic"
                              src={`/api/institute/logo/${email}/${cover}`}

                            />}
                        </div>
                        <div className="flex gap-2 py-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("TagLines")}{" "}
                          </h2>
                          <input
                            id="taglines"
                            name="taglines"
                            type="text"
                            value={taglines}
                            onChange={(e) => setTaglines(e.target.value)}
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                            placeholder={t("Sector")}
                            required
                          />
                        </div>
                        <div className="flex gap-2 py-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2">
                            {t("Description")}{" "}
                          </h2>
                          <textarea
                            rows={2}
                            cols={4}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            name="description"
                            className="w-3/4 border-solid border-2 py-2 px-3 rounded-[0.375rem]"
                            placeholder={t("Describe your business")}
                          >
                          </textarea>
                        </div>
                        <div className="flex gap-2 py-2 items-center">
                          <h2 className="w-1/4 text-[16px] mb-2"> </h2>
                          <div className="w-3/4 flex justify-start">
                            <button
                              onClick={updateBranding}
                              className="border border-1 border-solid !border-greslernorange hover:!bg-transparent hover:!text-greslernorange rounded-[0.375rem] px-3 py-2 mr-5 text-white bg-greslernorange">
                              {t("Save Change")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
}
export default InstituteProfile;