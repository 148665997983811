import React, { useEffect, useState } from 'react';
import UsesTable from '../tables/UsesTable';
import { useSelector } from 'react-redux';
import { selectEmail, selectUserId, selectInstitute } from '../../../../redux/Reducer/authSlice';
import { FrequentService, GetResource, GetUsers } from '../../../../services';
import GetUserList from '../../../../services/GetUserList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInstitution, faMailBulk, faPhone, faPrint, faSortDesc } from '@fortawesome/free-solid-svg-icons';
import { MembersRadialLinear } from './charts/MembersRadialLinear';
import ResourcesTable from '../tables/ResourcesTable';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";

interface user {
    _id: string;
    username: string;
    name: string;
    email: string;
    type: string
    country: string;
    gender?: "male" | "female" | " ";
    attempt: number;
    isVerified: boolean;
    guidelineAccepted: boolean;
    status: string;
    profilephoto: string;
    role: string
}

interface Iinstitute {
    // Institution Information
    //   id: string;
    institutionName: string;
    type: 'School' | 'College' | 'Company' | 'Non Profit' | 'Other' | 'Training center' | 'University';
    industry: 'Education' | 'Finance' | 'Company' | 'HealthCare' | 'Other';

    // Contact Information
    name: string;
    username: string;
    password: string;
    email: string;
    phone: string;
    position: string;
    user: user[];

    // Institution Address
    country: string;
    city?: string;
    streetAddress: string;
    zipCode: string;

    //Account Setup
    attempt: number;
    isVerified: boolean;
    guidelineAccepted: boolean;
    status: string;
    profilePhoto: string;
    emailVerificationToken?: string; // Optional
    emailVerificationExpires?: Date; // Optional
    resetToken?: string;
    resetTokenExpiration?: Date;
    description: string;
    logo: string;
    cover: string;
    taglines: string;
    groupId: string;
}
export default function Report() {
    const userId = useSelector(selectUserId)
    const [membersListActive, setMembersListActive] = useState([]);
    const [membersListDeactive, setMembersListDeactive] = useState([]);
    const [membersListPending, setMembersListPending] = useState([]);
    const [isUsers, setIsUsers] = useState(false);
    const [isResource, setIsResource] = useState(false)
    const [filteredResources, setFilteredResources] = useState([])
    const [filteredPostedResources, setFilteredPostedResources] = useState([])
    const [filteredPublicResources, setFilteredPublicResources] = useState([])
    const [filteredPrivateResources, setFilteredPrivateResources] = useState([])
    const [instituteDetail, setInstituteDetail] = useState<Iinstitute>()
    const instituteId = useSelector(selectInstitute)
    let resources = []
    const currentDate = new Date();
    const { t } = useTranslation();
    let filteredPublicResources_r = []
    let filteredPostedResources_r = []
    let filteredPrivateResources_r = []
    // Get the day, month, and year
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-indexed
    const year = currentDate.getFullYear();
    // Construct the date string in the format DD/MM/YYYY
    const dateString = `${day}/${month}/${year}`;
    const fetchlistofusers = async (instituteId) => {
        setIsUsers(false)
        const members = await GetUsers.getmembersList(instituteId);
        const deactiveData = await GetUsers.getuserlistdeactivate(instituteId);
        const pendingData = await GetUsers.getuserlistpending(instituteId);
        if (members) {
            members.map((resData) => {
                if (resData.status === null || resData.status === "0") {
                    setMembersListActive(membersListActive => [...membersListActive, resData]);
                } else if (resData.status === '2') {
                    setMembersListDeactive(membersListDeactive => [...membersListDeactive, resData]);
                }
            })
            setMembersListPending(pendingData)
            setIsUsers(true)
        }

    }
    const fetchResourcesList = async (instituteId) => {
        try {
            const result = await GetResource.getresource();
            const users = await GetUserList.getmembersList(instituteId)
            const members = []
            users.forEach((user) => {
                if (user.institute === instituteId) {
                    members.push(user._id)
                }
            })
            result.forEach((r) => {

                if (members.includes(r?.userId?.toString())) {
                    resources.push(r)
                    if (r.status === 'public') {
                        filteredPublicResources_r.push(r)
                        if (r.forcePublic) {
                            filteredPostedResources_r.push(r)
                        }
                    } else {
                        filteredPrivateResources_r.push(r)
                    }
                }

            })
            setFilteredResources(resources);
            setFilteredPublicResources(filteredPublicResources_r)
            setFilteredPostedResources(filteredPostedResources_r)
            setFilteredPrivateResources(filteredPrivateResources_r)
            setIsResource(true)

        } catch (error) {
            console.error("Error fetching resources list:", error);
            setIsResource(false)
        }
    };
    const fetchInstituteDetail = async (instituteId) => {
        const data = await GetUsers.getinstitutedetail(instituteId)
        setInstituteDetail(data)
    }
    const addtousedlink = async () => {
        const useddata = {
            usedlink: "report"
        }
        try {
            await FrequentService.addusedlink(useddata);
        } catch (error) { }
    };
    useEffect(() => {
        if (instituteId) {
            addtousedlink();
            fetchlistofusers(instituteId);
            fetchInstituteDetail(instituteId)
            resources?.length > 0 ? setFilteredResources(resources) : fetchResourcesList(instituteId);
        }
    }, [instituteId]);

    // const createPDF = async () => {

    //     const pdf = new jsPDF("portrait", "pt", "a4");
    //     const data = await document.querySelector("#pdf_report");
    //     pdf.html(data).then(() => {
    //       pdf.save("institution_report.pdf");
    //     });
    //   };
    const createPDF = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.querySelector("#pdf_report"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("report.pdf");
    };
    return (
        <>
            <div className="p-4">
                <div className="flex items-end justify-end space-x-3">
                    <button onClick={createPDF} className="px-4 py-2 text-sm text-white bg-greslernorange outline outline-offset-2 outline-2 rounded-sm hover:bg-white hover:!text-greslernorange">
                        <FontAwesomeIcon icon={faPrint} />{" "} {t("Print")}
                    </button>
                </div>
            </div>
            <div className="flex items-center h-auto bg-gray-100 w-full">
                <div id="pdf_report" className="w-full bg-white">
                    <div className="flex justify-between p-4 pb-0">
                        <div className='text-greslernblue w-1/4'>
                            <h1 className="text-3xl font-extrabold tracking-widest uppercase"><FontAwesomeIcon icon={faInstitution} />{" "}{instituteDetail?.institutionName}</h1>
                            <h3 className="text-3xl tracking-widest"><FontAwesomeIcon icon={faHome} />{" "}{instituteDetail?.type}</h3>
                            <br />
                            {/* <p className="text-base"><FontAwesomeIcon icon={faMailBulk} />{" "}{instituteDetail?.user.find(usr => usr.email === instEmail)?.email}</p> */}
                            <p className="text-base"><FontAwesomeIcon icon={faPhone} />{" "}{instituteDetail?.phone}</p>
                        </div>
                        <div className="flex justify-center place-self-center w-2/4">
                            <div className="w-24 h-24 rounded-full bg-greslernorange outline-dashed outline-2 outline-offset-2 outline-blue-500">
                                {instituteDetail?.logo
                                    ? <img
                                        src={`/api/public/images/${instituteDetail.logo}`}
                                        className="w-24 h-24 items-center rounded-full text-greslernorange text-2xl text-center"
                                    />
                                    : undefined
                                    // <div className="w-24 h-24 items-center flex justify-center place-self-center text-white text-7xl text-center uppercase">
                                    //     <span>{instituteDetail?.institutionName.charAt(0)}</span>
                                    // </div>
                                }
                            </div>
                        </div>
                        <div className="p-2 w-1/4 flex justify-end place-items-center">
                            <ul className="flex border-l-2 border-greslernblue">
                                <li className="flex flex-col p-2 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-blue-600" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <span className="text-sm">
                                        {instituteDetail?.city}, {" "}
                                        {instituteDetail?.country}
                                    </span>
                                    <span className="text-sm">
                                        {instituteDetail?.streetAddress}, {" "}
                                        {instituteDetail?.zipCode}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full h-0.5 bg-greslernorange"></div>
                    <div className='flex flex-col place-items-end text-greslernorange place-content-end p-2'>
                        <a href='https://greslern.osc.int/' className='w-fit text-3xl font-bold'>{t("GreSLERN")}</a>
                        <span className='text-xs capitalize w-fit'>{t("Greater South Learning enrichment resource nexus")}</span>
                    </div>
                    {/* <div className="w-full h-0.5 bg-greslernorange"></div> */}
                    <div className="flex justify-between p-4">
                        <div>
                            {/* <h6 className="font-bold">Start Date : <span className="text-sm font-medium"> {instituteDetail.}</span></h6> */}
                            <h6 className="font-bold">{t("Date")} : <span className="text-sm font-medium"> {dateString}</span></h6>
                        </div>
                        <div></div>
                    </div>
                    <div className='w-full flex justify-center place-self-center p-4'>
                        <p className='w-2/4 text-center first-letter:text-3xl first-letter:text-blue-600 first-letter:pr-1'>{t("The following is a comprehensive overview of the recent state of the institution on")} <b className='text-greslernorange'>{t("GresLERN")}</b>, {t("including statistics on report members, user invitations, and the utilization of member user resources")}</p>
                    </div>
                    <div className="flex justify-evenly p-4">
                        <div className="border-b border-gray-200  w-1/3">
                            <table className="">

                                <tbody className="bg-white">
                                    <tr className='whitespace-norap'>
                                        <td className=' text-base text-center text-gray-500 p-2'>
                                            summary of institution member registration until {dateString} some account are not activated yet.
                                        </td>
                                    </tr>
                                    <tr className="whitespace-nowrap">
                                        <td className="px-6 py-4 text-right">
                                            <div className="text-xl text-green-600 pr-1">activated invitations{" "}<b>{membersListActive?.length}</b></div>
                                            <div className="text-xl text-red-600 pr-1">deactivated members{" "}<b>{membersListDeactive?.length}</b></div>
                                            <div className="text-xl text-yellow-500 pr-1">pending invitations{" "}<b>{membersListPending?.length}</b></div>
                                        </td>
                                    </tr>
                                    <tr className=' whitespace-nowrap border-b border-greslernorage'>
                                        <td className="px-6 py-4">
                                            <div className=" font-bold text-2xl text-blue-600 pr-1 w-full text-right">
                                                Total number of registered users{" "}<b>{membersListDeactive?.length + membersListActive?.length + membersListPending?.length}</b>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className='whitespace-norap'>
                                        <td className=' text-base text-center text-gray-500 p-2 '>
                                            summary of institution member resource activity  until {dateString}. please note that resources are mange by the member user.
                                        </td>
                                    </tr>
                                    <tr className="whitespace-nowrap">
                                        <td className="px-6 py-4 text-right">
                                            <div className="text-xl text-greslernorange pr-1">posted resource{" "}<b>{filteredPostedResources?.length}</b></div>
                                            <div className="text-xl text-greslernorange pr-1">public resource{" "}<b>{filteredPublicResources?.length}</b></div>
                                            <div className="text-xl text-greslernorange pr-1">private resource{" "}<b>{filteredPrivateResources?.length}</b></div>
                                        </td>
                                    </tr>
                                    <tr className=' whitespace-nowrap border-b border-greslernorage'>
                                        <td className="px-6 py-4">
                                            <div className=" font-bold text-2xl text-blue-600 pr-1 w-full text-right">
                                                {t("SignatureTotal number of resource")} {" "}<b>{filteredResources?.length}</b>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="text-gray-800 bg-gray-800">
                                        <th ></th>
                                        <td className="text-sm font-bold"><b></b></td>
                                        <td className="text-sm font-bold"><b>.</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='border-b border-gray-200 shadow w-1/3'>
                            <MembersRadialLinear activeMembers={membersListActive?.length} pendingMembers={membersListPending?.length} deactiveMembers={membersListDeactive?.length} />
                        </div>
                    </div>

                    <div className="w-full h-0.5 bg-greslernorange"></div>
                    <div className='w-full flex justify-center place-self-center p-4'>
                        <p className='w-2/4 text-center first-letter:text-3xl first-letter:text-blue-600 first-letter:pr-1'>Please find below the list of statistical data pertaining to the resources of institutional member users.</p>
                    </div>
                    <div className='flex justify-center place-self-center w-full mt-2'>
                        <div className="block w-auto overflow-x-auto  border">
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                            #
                                        </th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                            {t("user name")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                            {t("resource name")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px">
                                            {t("resource status")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("shared to")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("tagged to")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("likes")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("comments")}</th>
                                        <th
                                            className="px-4 bg-gray-50 text-greslernorange align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap ">
                                            {t("resource date")}</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-100">
                                    {
                                        filteredResources?.map((res: any, key: number) =>
                                            <tr className="text-gray-500" key={key}>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                    {key + 1}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 p-4">
                                                    {res.username}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 p-4">
                                                    {res.title}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                    {res.status}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                    {res.sharedTo?.length}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                    {res.taggedTo?.length}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                    {res.likes?.length}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 whitespace-nowrap p-4">
                                                    {res.comments?.length}
                                                </td>
                                                <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-500 p-4">
                                                    {new Date(res.creationDate).toDateString()}{" "}
                                                    {new Date(res.creationDate).toLocaleTimeString()}
                                                </td>

                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex justify-between p-4">
                        <div>
                            <h3 className="text-xl">{t("General Institution Summery")}</h3>
                            <p>{t("data on this report include")}:</p>
                            <ul className="text-xs list-disc list-inside">
                                <li>{t("summary of members until")} {dateString}.</li>
                                <li>{t("summary of resources until")} {dateString}.</li>
                            </ul>
                        </div>
                        <div className="p-4">
                            <h3>{t("Signature")}</h3>
                            <div className="text-4xl italic text-indigo-500">_______</div>
                        </div>
                    </div>
                    <div className="w-full h-0.5 bg-indigo-500"></div>


                </div>
            </div>
        </>
    );
}
