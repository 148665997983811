
import { useEffect, useState } from "react";
import { GetUsers } from "../../../services";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faUserClock, faUserCog, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CardContainer from "../../AdminComponents/components/cards/CardContainer";
import { useSelector } from 'react-redux';
import { selectInstitute } from '../../../redux/Reducer/authSlice';
const CardOne = () => {
  const instituteId = useSelector(selectInstitute)
  const [countActive, setCountActive] = useState(0);
  const [countDeactivated, setCountDeactivated] = useState(0);
  const [counterPending, setCounterPending] = useState(0);
  const { t } = useTranslation();

  const fetchlistofteams = async () => {
    const data = await GetUsers.getmembersList(instituteId);
    if (data) {
      data.map((resData) => {
        if (resData.status === null || resData.status.toString() === '0') {
          setCountActive(prevCounter => prevCounter + 1);
        } else if (resData.status === '2') {
          setCountDeactivated(countDeactivated + 1);
        }
      })
    }
  }
  const navigate = useNavigate();
  const handleNavigationActive = () => {
    navigate('/institute/team', { state: { value: 0 } });
  };
  const handleNavigationDeactivated = () => {
    navigate('/institute/team', { state: { value: 2 } });
  };
  const handleNavigationAll = () => {
    navigate('/institute/team', { state: { value: 3 } });
  };
  const handleNavigationPending = () => {
    navigate('/institute/team', { state: { value: 5 } });
  };
  const fetchinvited = async () => {
    const data = await GetUsers.getuserlistpending(instituteId);
    if (data) {
      setCounterPending(data.length);
    }

  }
  useEffect(() => {
    if (instituteId) {
      fetchinvited();
      fetchlistofteams();
    }
  }, [instituteId])

  return (
    <div className="flex flex-col md:flex-row p-3 gap-4 justify-center w-full">
      <div className="element-selector2 md:w-1/4">
        <button className="w-full"
          onClick={handleNavigationAll}
        >
          <CardContainer
            icon={
              <FontAwesomeIcon icon={faUsersViewfinder} color="white" size="2x" />
            }
            title={t("Total Team")} subTitle={t("alluserunderyou")}
            statistics={
              <div className="flex flex-col items-center justify-center uppercase">
                <p className="text-2xl font-bold text-white ">
                  {countActive + countDeactivated + counterPending}
                </p>
                <p className="text-sm font-normal text-white/50">{t("Users")}</p>
              </div>
            }
            iconBgColor='bg-greslernblue'
            shadowColor="shadow-greslernblue"
          />
        </button>
      </div>
      <div className="element-selector md:w-1/4">
        <button className="w-full"
          onClick={handleNavigationActive}
        >
          <CardContainer
            icon={
              <FontAwesomeIcon icon={faUserCheck} color="white" size="2x" />
            }
            title="Active" subTitle={t("activememebers")}
            statistics={
              <div className="flex flex-col items-center justify-center">
                <p className="text-2xl font-bold text-white ">
                  {countActive}
                </p>
                <p className="text-sm font-normal text-white/50">{t("Users")}</p>
              </div>
            }
            iconBgColor='bg-green'
            shadowColor="shadow-green"
          />
        </button>
      </div>
      <div className=" md:w-1/4">
        <button className="w-full"
          onClick={handleNavigationDeactivated}
        >
          <CardContainer
            icon={
              <FontAwesomeIcon icon={faUserCog} color="white" size="2x" />
            }
            title="Deactivated" subTitle={t("deactivatedmemebers")}
            statistics={
              <div className="flex flex-col items-center justify-center">
                <p className="text-2xl font-bold text-white ">
                  {countDeactivated}
                </p>
                <p className="text-sm font-normal text-white/50">{t("Users")}</p>
              </div>
            }
            iconBgColor='bg-red-500'
            shadowColor="shadow-red-500"
          />
        </button>
      </div>
      <div className=" md:w-1/4">
        <button className="w-full"
          onClick={handleNavigationPending}
        >
          <CardContainer
            icon={
              <FontAwesomeIcon icon={faUserClock} color="white" size="2x" />
            }
            title="Pending" subTitle={t("pendingmembers")}
            statistics={
              <div className="flex flex-col items-center justify-center">
                <p className="text-2xl font-bold text-white ">
                  {counterPending}
                </p>
                <p className="text-sm font-normal text-white/50">{t("Users")}</p>
              </div>
            }
            iconBgColor='bg-yellow-300'
            shadowColor="shadow-yellow-300"
          />
        </button>
      </div>
    </div>
  );
};

export default CardOne;
