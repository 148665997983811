import React, { useEffect, useState } from 'react';
import ResourcesTable from './tables/ResourcesTable';
import { FrequentService } from '../../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { faAdd, faArrowTrendUp, faClock, faPlusCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import ContentHeader from '../../root/dashboard/addon/contentHeader';
import GroupView from './Grouping/GroupView';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import CreateRosource from './CreateResource';
import CreateGroup from './Grouping/CreateGroup';

export default function Resource() {
  const [showGroup, setShowGroup] = useState(false);
  const [showMyResource, setShowMyResource] = useState(false);
  const [showTeamResource, setShowTeamResource] = useState(true);
  const [showPopularResource, setShowPopularResource] = useState(false);
  const [showResourceAnalisis, setShowResourceAnalisis] = useState(false);
  const [showNewContentModal, setShowNewContentModal] = useState(false);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const { t } = useTranslation();
  const handleShowMyResource = () => {
    setShowGroup(false);
    setShowMyResource(true);
    setShowTeamResource(false);
    setShowPopularResource(false);
    setShowResourceAnalisis(false);
  }
  const handleShowGroup = () => {
    setShowGroup(true);
    setShowMyResource(false);
    setShowTeamResource(false);
    setShowPopularResource(false);
    setShowResourceAnalisis(false);
  }
  const handleTeamResource = () => {
    setShowGroup(false);
    setShowMyResource(false);
    setShowTeamResource(true);
    setShowPopularResource(false);
    setShowResourceAnalisis(false);
  }
  const handlePopularResource = () => {
    setShowGroup(false);
    setShowMyResource(false);
    setShowTeamResource(false);
    setShowPopularResource(true);
    setShowResourceAnalisis(false);
  }
  const handleAnalisisResource = () => {
    setShowGroup(false);
    setShowMyResource(false);
    setShowTeamResource(false);
    setShowPopularResource(false);
    setShowResourceAnalisis(true);
  }
  const addtousedlink = async () => {
    const useddata = {
      usedlink: "resource"
    }
    try {
      await FrequentService.addusedlink(useddata);
    } catch (error) { }
  };
  const handlecreateGroup = () => {
    setShowGroupForm(!showGroupForm);
  }
  useEffect(() => {
    // addtousedlink();
  }, []);
  return (
    <>
      <ContentHeader title={t("Latest resources")} showIcon="false" />
      <div className="flex justify-between bg-transparent mb-2 ">
        <div className="">
          <ul className="flex flex-wrap text-center text-greslernorange ">
            <li className="me-2"
              onClick={handleShowMyResource}
            >
              <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group 
                ${showMyResource ? " !border-greslernorange font-extrabold " : ""}`}>
                <FontAwesomeIcon icon={faPlusCircle} />
                <span>{t("My Resource")}</span>
              </div>
            </li>
            <li className="me-2"
              onClick={handleTeamResource}
            >
              <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group 
                ${showTeamResource ? " !border-greslernorange font-extrabold " : ""}`}>
                <FontAwesomeIcon icon={faTeamspeak} />
                <span>{t("Team Resource")}</span>
              </div>
            </li>
            <li className="me-2"
              onClick={handlePopularResource}
            >
              <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group 
                ${showPopularResource ? " !border-greslernorange font-extrabold " : ""} `}>
                <FontAwesomeIcon icon={faArrowTrendUp} />
                <span> {t("Popular")}</span>
                <div className="mb-1 bg-orange-300">
                </div>
              </div>
            </li>
            <li className="me-2"
              onClick={handleAnalisisResource}
            >
              <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group 
                ${showResourceAnalisis ? " !border-greslernorange font-extrabold " : ""} `}>
                <FontAwesomeIcon icon={faClock} />
                <span> {t("Analisis")}</span>
                <div className="mb-1 bg-orange-300">
                </div>
              </div>
            </li>
            <li className="me-2"
              onClick={handleShowGroup}
            >
              <div className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:font-extrabold hover:border-greslernorange  group active"  ${showGroup ? " !border-greslernorange font-extrabold " : ""} `}>
                <FontAwesomeIcon icon={faUsers} />
                <span>{t("Group")}</span>
                <div className="mb-1 bg-orange-300 border border-spacing-4">
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="">
          {showGroup ?
            <button
              onClick={handlecreateGroup}
              className=" bg-greslernorange font-semibold text-white hover:!bg-white hover:!text-greslernorange py-2 px-2 border border-white hover:border-transparent rounded">
              <FontAwesomeIcon icon={faAdd} /> {" "}
              {t("Create Group")}
            </button>
            :
            <h4
              // href='create/'
              className=" bg-greslernorange font-semibold text-white hover:!bg-white hover:!text-greslernorange py-2 px-2 border border-white hover:border-transparent rounded">
              <FontAwesomeIcon icon={faAdd} /> {" "}
              {t("Create Resource")}
            </h4>
          }
        </div>
      </div>
      <div className="w-full">
        {showMyResource && <ResourcesTable status={0} />}
        {showTeamResource && <ResourcesTable status={1} />}
        {showPopularResource && <ResourcesTable status={2} />}
        {showResourceAnalisis && <ResourcesTable status={3} />}
        {showGroup && <GroupView isShowRes={true} />}
        {showNewContentModal && <CreateRosource />}
        {showGroupForm && <CreateGroup onClose={setShowGroupForm} />}

      </div>

    </>
  );
}
